'use client';

import { chakra } from '@chakra-ui/react';

import FacebookSvg from '@/assets/icon/Facebook.svg';
import GoogleMyBusinessSvg from '@/assets/icon/GoogleMyBusiness.svg';
import InstagramSvg from '@/assets/icon/Instagram.svg';
import AppleSvg from '@/assets/icon/Apple.svg';
import YahooSvg from '@/assets/icon/Yahoo.svg';
import HomepageSvg from '@/assets/icon/Homepage.svg';
import XTwitterSvg from '@/assets/icon/XTwitter.svg';
import LINE from '@/assets/icon/LINE.svg';
import YouTube from '@/assets/icon/YouTube.svg';
import Yelp from '@/assets/icon/Yelp.svg';
import TikTok from '@/assets/icon/TikTok.svg';

import AddSvg from '@/assets/icon/Add.svg';
import AddCircleSvg from '@/assets/icon/AddCircle.svg';
import AddPhotoSvg from '@/assets/icon/AddPhoto.svg';
import AttentionCircleSvg from '@/assets/icon/AttentionCircle.svg';
import ArrowCircleSvg from '@/assets/icon/ArrowCircle.svg';
import ArrowDownSvg from '@/assets/icon/ArrowDown.svg';
import ArrowLeftSvg from '@/assets/icon/ArrowLeft.svg';
import ArrowRightSvg from '@/assets/icon/ArrowRight.svg';
import ArrowTopSvg from '@/assets/icon/ArrowTop.svg';
import CalendarSvg from '@/assets/icon/Calendar.svg';
import CheckSvg from '@/assets/icon/Check.svg';
import CheckCircleSvg from '@/assets/icon/CheckCircle.svg';
import ChevronDownSvg from '@/assets/icon/ChevronDown.svg';
import ChevronLeftSvg from '@/assets/icon/ChevronLeft.svg';
import ChevronRightSvg from '@/assets/icon/ChevronRight.svg';
import CloseSvg from '@/assets/icon/Close.svg';
import CloseCircleSvg from '@/assets/icon/CloseCircle.svg';
import DocumentSvg from '@/assets/icon/Document.svg';
import DocumentsSvg from '@/assets/icon/Documents.svg';
import EditCircleSvg from '@/assets/icon/EditCircle.svg';
import HintCircleSvg from '@/assets/icon/HintCircle.svg';
import NoticeSvg from '@/assets/icon/Notice.svg';
import NoResultsSvg from '@/assets/icon/NoResults.svg';
import RemoveSvg from '@/assets/icon/Remove.svg';
import RemoveCircleSvg from '@/assets/icon/RemoveCircle.svg';
import SearchSvg from '@/assets/icon/Search.svg';
import SyncSvg from '@/assets/icon/Sync.svg';
import UserIconSvg from '@/assets/icon/UserIcon.svg';
import VideoSvg from '@/assets/icon/Video.svg';
import ViewSvg from '@/assets/icon/View.svg';
import Star from '@/assets/icon/Star.svg';
import HalfStar from '@/assets/icon/HalfStar.svg';

import SvgBrand from '@/assets/icon/Brand.svg';
import SvgBrandSolid from '@/assets/icon/Brand_solid.svg';
import SvgBulk from '@/assets/icon/Bulk.svg';
import SvgBulkSolid from '@/assets/icon/Bulk_solid.svg';
import SvgCompany from '@/assets/icon/Company.svg';
import SvgCompanySolid from '@/assets/icon/Company_solid.svg';
import SvgCustomize from '@/assets/icon/Customize.svg';
import SvgCustomizeSolid from '@/assets/icon/Customize_solid.svg';
import SvgHand from '@/assets/icon/Hand.svg';
import SvgHandSolid from '@/assets/icon/Hand_solid.svg';
import SvgHome from '@/assets/icon/Home.svg';
import SvgHomeSolid from '@/assets/icon/Home_solid.svg';
import SvgPerformance from '@/assets/icon/Performance.svg';
import SvgPerformanceSolid from '@/assets/icon/Performance_solid.svg';
import SvgPhoto from '@/assets/icon/Photo.svg';
import SvgPhotoSolid from '@/assets/icon/Photo_solid.svg';
import SvgPost from '@/assets/icon/Post.svg';
import SvgPostSolid from '@/assets/icon/Post_solid.svg';
import SvgReview from '@/assets/icon/Review.svg';
import SvgReviewSolid from '@/assets/icon/Review_solid.svg';
import SvgStore from '@/assets/icon/Store.svg';
import SvgStoreSolid from '@/assets/icon/Store_solid.svg';
import SvgLogout from '@/assets/icon/Logout.svg';

export const IconMapper = {
  facebook: FacebookSvg,
  gbp: GoogleMyBusinessSvg,
  instagram: InstagramSvg,
  yahoo: YahooSvg,
  apple: AppleSvg,
  homepage: HomepageSvg,
  xtwitter: XTwitterSvg,
  line: LINE,
  youtube: YouTube,
  yelp: Yelp,
  tiktok: TikTok,

  add: AddSvg,
  addCircle: AddCircleSvg,
  addPhoto: AddPhotoSvg,
  arrowCircle: ArrowCircleSvg,
  arrowDown: ArrowDownSvg,
  arrowLeft: ArrowLeftSvg,
  arrowRight: ArrowRightSvg,
  arrowTop: ArrowTopSvg,
  attention: AttentionCircleSvg,
  calendar: CalendarSvg,
  check: CheckSvg,
  checkCircle: CheckCircleSvg,
  chevronDown: ChevronDownSvg,
  chevronLeft: ChevronLeftSvg,
  chevronRight: ChevronRightSvg,
  close: CloseSvg,
  closeCircle: CloseCircleSvg,
  document: DocumentSvg,
  documents: DocumentsSvg,
  editCircle: EditCircleSvg,
  hintCircle: HintCircleSvg,
  notice: NoticeSvg,
  noResults: NoResultsSvg,
  remove: RemoveSvg,
  removeCircle: RemoveCircleSvg,
  search: SearchSvg,
  sync: SyncSvg,
  userIcon: UserIconSvg,
  video: VideoSvg,
  view: ViewSvg,

  brand: SvgBrand,
  brand_solid: SvgBrandSolid,
  bulk: SvgBulk,
  bulk_solid: SvgBulkSolid,
  company: SvgCompany,
  company_solid: SvgCompanySolid,
  customize: SvgCustomize,
  customize_solid: SvgCustomizeSolid,
  hand: SvgHand,
  hand_solid: SvgHandSolid,
  home: SvgHome,
  home_solid: SvgHomeSolid,
  performance: SvgPerformance,
  performance_solid: SvgPerformanceSolid,
  photo: SvgPhoto,
  photo_solid: SvgPhotoSolid,
  post: SvgPost,
  post_solid: SvgPostSolid,
  review: SvgReview,
  review_solid: SvgReviewSolid,
  store: SvgStore,
  store_solid: SvgStoreSolid,
  logout: SvgLogout,
  star: Star,
  half_star: HalfStar,
};

export const pixel = { xs: 12, sm: 14, md: 16, lg: 24, xl: 48 };
export type Size = keyof typeof pixel;

export type IconName = keyof typeof IconMapper;

type IconProps = {
  name: IconName;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  transform?: string;
};

export function Icon({
  name,
  color = 'gray.300',
  size = 'md',
  transform,
}: IconProps) {
  const IconComponent = IconMapper[name];
  const px = pixel[size];

  return (
    <chakra.svg
      fill={color}
      width={`${px}px`}
      height={`${px}px`}
      viewBox="0 0 24 24"
      transform={transform}
    >
      <IconComponent />
    </chakra.svg>
  );
}
